<div class="panel card" [ngClass]="panelClass">
  <div
    class="panel-heading card-header"
    role="tab"
    (click)="toggleOpen()"
    [ngClass]="isDisabled ? 'panel-disabled' : 'panel-enabled'"
  >
    <div class="panel-title">
      <div role="button" class="accordion-toggle" [attr.aria-expanded]="isOpen">
        <button class="btn btn-link" *ngIf="heading" [ngClass]="{ 'text-muted': isDisabled }" type="button">
          {{ heading }}
        </button>
        <ng-content select="[accordion-heading]"></ng-content>
      </div>
    </div>
  </div>
  <div class="panel-collapse collapse" role="tabpanel" [collapse]="!isOpen" [isAnimated]="isAnimated">
    <div class="panel-body card-block card-body">
      <ng-content></ng-content>
    </div>
  </div>
</div>
